require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
require("trix")
require("@rails/actiontext")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require("vendor/jquery.fancybox")
require("vendor/jquery-ui")
require("vendor/select2.full")

require("vendor/slick")

var Sticky = require('sticky-js');
window.Sticky = Sticky

import tippy from "tippy.js";
// import { hideAll } from 'tippy.js';
window.tippy = tippy;






$( window ).resize(function() {
  var sticky = new Sticky('.sticky');
});



$(document).on('ready turbolinks:load', function() {
  jQuery.extend(jQuery.fancybox.defaults, {
    loop: true
  });

  detectScroll();
  // sliding thumbs
  $('.sliding-thumbs-three').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  });

  $('.sliding-thumbs-four').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  });

  $('.slider-controls').on('click', '.next', function(e){
    e.preventDefault();
    $(this).parents('.sliding-thumbs-wrapper').find('.slick-slider').slick('slickNext');
  });
  $('.slider-controls').on('click', '.prev', function(e){
    e.preventDefault();
    $(this).parents('.sliding-thumbs-wrapper').find('.slick-slider').slick('slickPrev');
  });



  var sticky = new Sticky('.sticky');

  tippy("[data-tippy-content]", {});

  $("select[multiple]").select2({
    closeOnSelect: false,
  });


  // To prevent mobile menu from blinking
  $(document).on("click", "#mobile-menu a", function (e) {
    parent.jQuery.fancybox.getInstance().close();
  });

  // select all button
  $("[data-select-all]").on("click", function (e) {
    e.preventDefault();
    var selectDom = $(this).data("select-all");
    // console.log(selectDom);
    $(selectDom + " > option").prop("selected", "selected");
    $(selectDom).trigger("change");
  });

  var attachmentInput = document.getElementById('semnal_attachment'); /* finds the input */

  function changeLabelText() {
      var attachmentInputValue = attachmentInput.value; /* gets the filepath and filename from the input */
      var fileNameStart = attachmentInputValue.lastIndexOf('\\'); /* finds the end of the filepath */
      attachmentInputValue = attachmentInputValue.substr(fileNameStart + 1); /* isolates the filename */
      var attachmentInputLabel = $('label[for="semnal_attachment"]')
      attachmentInputLabel.addClass('has-file')
      attachmentInputLabel.find('span').text(attachmentInputValue)
  }

  if (attachmentInput != undefined) {
    attachmentInput.addEventListener('change',changeLabelText,false); /* runs the function whenever the filename in the input is changed */
  }


  $('[scroll-to]').on('click', function(e){
    e.preventDefault();

    $('html, body').animate({
      // scrollTop: $( $.attr(this, 'scroll-to') ).offset().top
      scrollTop: $( $.attr(this, 'href') ).offset().top - 60
    }, 500);

    jQuery.fancybox.getInstance().close();

    return false;
  });



  var from = $("#range-start")
  .datepicker({
      dateFormat: 'yy-mm-dd',
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1,
      minDate: $("#range-end").attr('data-min-date'),
      maxDate: $("#range-end").attr('data-max-date'),
    })
    .on( "change", function() {
      $(this).parent('.date-picker').removeClass('default')
    }),
  to = $("#range-end").datepicker({
    dateFormat: 'yy-mm-dd',
    changeMonth: true,
    changeYear: true,
    numberOfMonths: 1,
    minDate: $("#range-end").attr('data-min-date'),
    maxDate: $("#range-end").attr('data-max-date'),
  })
  .on( "change", function() {
    $(this).parent('.date-picker').removeClass('default')
  });



})


// Optimistics
$(document).on('click', '.votes a.vote', function(e){
  // console.log("testttt");
  // var wrapper = $(e).parent('.votes')
  // console.log(e);
  // console.log(wrapper);
  // if (wrapper.hasClass('voted')) {
  //   console.log('removing class');
  //   wrapper.removeClass('voted')
  // } else {
  //   wrapper.addClass('voted')
  //   console.log('adding class');
  // }
})


$(document).on('turbolinks:before-cache', function() {
  $.datepicker.dpDiv.remove()
  $(".date-picker input").each(function(index, el) {
    $(el).datepicker("destroy")
  });
})


function getDate( element ) {
  var date;
  try {
    date = $.datepicker.parseDate( "yyyy-mm-dd", element.value );
  } catch( error ) {
    date = null;
  }
  return date;
}

function detectScroll() {
  var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  // console.log(scrollTop);
  if (scrollTop > 0) {
    $('body').addClass("scrolled")
  } else {
    $('body').removeClass("scrolled")
  }
}

document.addEventListener('scroll', function(e) {
  detectScroll();
});


// Open all external links in a new window
addEventListener(
  "click",
  function (event) {
    var el = event.target;
    if (
      el.tagName === "A" &&
      !el.isContentEditable &&
      el.host !== window.location.host
    ) {
      if ($(el).parents("article").length) {
        el.setAttribute("target", "_blank");
      }
    }
  },
  true
);